.red__button {
    box-shadow: -10px 10px var(--red);
}

.blue__button {
    box-shadow: -10px 10px var(--blue);
}

.button {
    display: flex;
    background: white;
    margin: 3rem 2rem;
    color: #000000;
    width: clamp(11rem, 33%, 18rem);
    padding: 1rem;
    font-size: clamp(1rem, 2vw, 2rem);
}

a {
    display: flex;
    justify-content: center;
    text-decoration: none;
}

@media (max-width: 600px){
    .button {
        padding: .25rem;
        margin: 1.5rem 1rem;
    }
}