.primary-header {
    align-items: center;
    justify-content: space-between;
    float: right;
    position: fixed;
    right: 0;
    z-index: 10;
  }

  .navbar__number {
    color: var(--red);
  }
  
  .primary-navigation {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
    background: hsl(0 0% 0%/ 0.5);
  }
  
  .mobile-nav-toggle {
    display: none;
    opacity: 0;
    transition: opacity .5s ease-in-out;
  }
  
  @supports (backdrop-filter: blur(1rem)) {
    .primary-navigation {
      background: hsl(0 0% 100% / 0.1);
      backdrop-filter: blur(.8rem);
    }
  }
  
  .primary-navigation a {
    text-decoration: none;
  }
  
  .primary-navigation a > [aria-hidden="true"] {
    font-weight: 700;
    margin-inline-end: .5em;
  }
  
  @media (max-width: 35em) {
    .primary-navigation {
      --gap: 3em;
      position: fixed;
      z-index: 999;
      inset: 0 0 0 30%;
      flex-direction: column;
      padding: min(30vh, 10rem) 1em;
      margin-top: 0;
      transform: translateX(100%);
      transition: transform 350ms ease-out;
    }
  
    .primary-navigation[data-visible="true"] {
      transform: translateX(0%);
    }
  
    .button {
      width: 2rem;
      height: 2rem;
    }
  
    .mobile-nav-toggle {
      display: block;
      opacity: 1;
      transition: opacity .5s ease-in-out;
      position: absolute;
      z-index: 9999;
      /* background: url(../icons/icon-hamburger.svg); */
      /* background-size: 2rem; */
      background: hsl(0 0% 100% / 0.1);
      backdrop-filter: blur(1rem);
      width: 3rem;
      height: 3rem;
      border: 0;
      aspect-ratio: 1;
      top: 2rem;
      right: 2rem;
      /* background-color: none; */
      background: none;
    }

    .mobile-nav-toggle .bar {
      width: 25px;
      height: 3px;
      margin: 5px auto;
      transition: all 0.3s ease-in-out;
      background: #fff;
      display: flex;
    }

    #mobile-menu.is-active .bar:nth-child(2) {
      opacity: 0;
    }
  
    #mobile-menu.is-active .bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }
  
    #mobile-menu.is-active .bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  
  }
  
  @media (min-width: 35em) and (max-width: 55em){
    .primary-navigation a > span {
      display: none;
    }
  }
  
  @media (min-width: 35em) {
    .primary-navigation {
      padding-block: 1rem;
      padding-inline: clamp(1rem, 10vw, 2rem);
    }
  }
  