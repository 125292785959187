.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
}

.box {
  display: flex;
  justify-content: space-evenly;
}

.privacy-policy {
  padding: 15px;
}

@media (max-width: 700px) {
  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
